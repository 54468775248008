import React, {FC, useEffect} from 'react';
import { useNavigate  } from "react-router";
import {Box, Button, Typography, makeStyles} from "@material-ui/core";
import {Routes} from "../../constants/routes";
import pageNotFoundImage from '../../assets/page-not-found.jpg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.grey[300],
    backgroundImage: `linear-gradient(0deg, rgba(60, 65, 80, 0.65), rgba(60, 65, 80, 0.65)), url(${pageNotFoundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  button: {
    marginTop: theme.spacing(9),
  }
}))

export const PageNotFound: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => navigate(Routes.NOT_FOUND), []);

  return (
      <Box flex='1 0 auto' className={classes.wrapper}>
        <Typography variant="h6">{t('eapi-404-error', {defaultValue: '404 Error'})}</Typography>
        <Typography variant="h2">{t('eapi-page-not-found-text', {defaultValue: 'Page not found'})}</Typography>
        <Button className={classes.button} variant="outlined" href={Routes.HOME}>{t('eapi-page-not-found-info', {defaultValue: 'Go to Developer Portal homepage'})}</Button>
      </Box>
  )
}