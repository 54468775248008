import React from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import { useAuth, useDialog } from '../../hooks';
import { Profile } from '../Profile';
import { SignInButton } from '../SignInButton';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export const SignIn = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const { handleOpen } = useDialog();
  const { t } = useTranslation();

  return user ? (
    <Profile />
  ) : (
    <Box display="flex" alignItems="center">
      <SignInButton>{t('eapi-login-text', {defaultValue: 'Login'})}</SignInButton>
      <>
        <Divider orientation="vertical" className={classes.divider} />
        <Button onClick={handleOpen}>{t('eapi-sign-up-text', {defaultValue: 'Sign up'})}</Button>
      </>
    </Box>
  );
};
