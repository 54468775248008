import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Entity } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import { ApiDetails } from './components';
import { useSubscription } from '../../../../../../hooks';

export const ApisTab: FC = () => {
  const { subscription } = useSubscription();
  const { t } = useTranslation();

  if (!subscription) return null;

  if (!subscription.services || !subscription.services.length)
    return (
      <Typography align="left">
        {t("eapi-subs-apis-empty-state-text")}
      </Typography>
    );

  return (
    <>
      {subscription.services.map((service: Entity) => (
        <ApiDetails key={service.metadata.name} service={service} />
      ))}
    </>
  );
};
