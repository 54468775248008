import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles<Theme>(
    theme => ({
      footer: {
        flexShrink: 0,
        borderTop: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        backgroundColor: theme.palette.grey[50],
      },
      subFooter: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        gap: theme.spacing(3),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
  
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
  
        [theme.breakpoints.up('xl')]: {
        },
      },
      linksList: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        textAlign: 'center',
  
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row'
        },
      },
      socialLinksList: {
        display: 'flex',
        gap: theme.spacing(1),
      },
      socialLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 51,
        height: 51,
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: '50%',
        backgroundColor: theme.palette.background.default,
        transition: 'border-color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s, transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s, -webkit-transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0s',
  
        '&:hover': {
          borderColor: theme.palette.grey[700]
        },
      },
      copyright: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(4),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
  
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
        },
  
        [theme.breakpoints.up('md')]: {
          justifyContent: 'flex-start',
        },
      },
    }),
    { name: 'DeveloperPortalFooter' },
  );
  