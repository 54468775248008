export enum FieldLabels {
  PUBLIC_KEY = "eapi-cred-asym-title-text",
}

export enum FieldNames {
  PUBLIC_KEY = 'publicKey',
}

export const PUB_KEY_PLACEHOLDER: Readonly<string> =
  '-----BEGIN PUBLIC KEY-----\n\
MII*************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\
****************************************************************\n\
-----END PUBLIC KEY-----';
