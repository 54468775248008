import { FC } from 'react';
import { OverviewTab } from './components/OverviewTab';
import { Entity } from '@backstage/catalog-model';
import { ServiceDocumentationTab } from '../../components/ServiceDocumentationTab';

export interface PanelComponentProps {
  entity?: Entity
}

export interface TabConfig {
  panelComponent: FC<PanelComponentProps>;
  key: string;
  path: string;
}

const TAB_KEYS = {
  OverviewTab: 'eapi-overview-text',
  ServiceDocumentationTab: 'eapi-doc-text',
};

export const TAB_PATHS = {
  OverviewTab: 'overview',
  ServiceDocumentationTab: 'documentation',
};

export const TABS_CONFIGURATION: ReadonlyArray<TabConfig> = [
  {
    panelComponent: OverviewTab,
    key: TAB_KEYS.OverviewTab,
    path: TAB_PATHS.OverviewTab,
  },
  {
    panelComponent: ServiceDocumentationTab,
    key: TAB_KEYS.ServiceDocumentationTab,
    path: TAB_PATHS.ServiceDocumentationTab,
  },
];
