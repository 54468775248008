import { EAPI_GENERIC_ERROR_KEY } from '@internal/plugin-eapi-common';
import { AxiosResponse, AxiosError } from 'axios';

export function handleError(error: unknown): never {

  if (error instanceof AxiosError) {
    const response: AxiosResponse | undefined = error.response;

    if (response) {
      const { status } = response;

      if (status === 401) {
        error.message = 'eapi-auth-error';
      } else if (status === 422) {
        error.message = 'eapi-not-allowed-error';
      }
    } else {
      error.message = EAPI_GENERIC_ERROR_KEY;
    }
  }

  throw error;
}
