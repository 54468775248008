import React, { FC, useMemo } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { LineChart } from '@mui/x-charts/LineChart';
import { Module, TIME_UNIT_RANGE_VALUES_MAPPING, stripTimeFromDate } from '@internal/plugin-eapi-common';
import { formatDate } from '@internal/plugin-eapi-react';
import { TimeUnitRangeValue } from '@internal/plugin-eapi-common';
import { ConsumptionView } from './components/ConsumptionView';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

interface ModuleInfoProps {
  module: Module;
  period: TimeUnitRangeValue;
}

const DATE_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as const;

const generateTimePeriodData = (period: number) => {
  const labels = [];
  const dateValues = [];
  const today = new Date();
  
  for (let i = period; i > 0; i--) {
    const day = new Date(today);
    day.setDate(today.getDate() - i + 1);
    labels.push(day.toLocaleDateString('default', { year: '2-digit', month: 'short', day: 'numeric' }));
    dateValues.push(stripTimeFromDate(day));
  }

  return {
    labels,
    dateValues,
  };
};

export const ModuleInfo: FC<ModuleInfoProps> = ({ module, period }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const contractStartDate = useMemo(() => formatDate(module.contractStartDate, DATE_TIME_OPTIONS), [module.contractStartDate]);
  const contractEndDate = useMemo(() => formatDate(module.contractEndDate, DATE_TIME_OPTIONS), [module.contractEndDate]);

  return (
    <Paper elevation={8} className={classes.wrapper}>
      <Grid item container spacing={4}>
        <Grid item sm={2}>
          <Typography variant="subtitle2">{t('eapi-module-name-text')}</Typography>
          <Typography variant="body2">{module.name}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="subtitle2">{t('eapi-contract-start-date-text')}</Typography>
          <Typography variant="body2">{contractStartDate}</Typography>
        </Grid>
        <Grid item sm={2}>
          <Typography variant="subtitle2">{t('eapi-contract-end-date-text')}</Typography>
          <Typography variant="body2">{contractEndDate}</Typography>
        </Grid>
        {module.types.map(({ name, consumption, timeSeries }) => {
          const timePeriodData = generateTimePeriodData(TIME_UNIT_RANGE_VALUES_MAPPING[period]);
          const dailyData: number[] = Array(timePeriodData.dateValues.length).fill(0);

          timeSeries.forEach(({ dateTime, daily }) => {
            const index = timePeriodData.dateValues.indexOf(dateTime);
            
            if (index !== -1) {
              dailyData[index] = daily;
            }
          });
          
          return (
            <Grid item xs={12} container key={name} className={classes.moduleType}>
              <Grid item sm={8} container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{t('eapi-type-text')}</Typography>
                  <Typography variant="body2">{name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LineChart
                    xAxis={[{ scaleType: 'point', data: timePeriodData.labels }]}
                    series={[{ data: dailyData }]}
                    height={300}
                  ></LineChart>
                </Grid>
              </Grid>
              <Grid item sm={4} container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">{t('eapi-total-consumption-text')}</Typography>
                  <Typography variant="body2">
                    {`${consumption.overall} ${consumption.creditLimit ? `/ ${consumption.creditLimit}` : ''}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={8} xl={6}>
                  <ConsumptionView data={consumption} />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};
