import { AppInsightsWrapper } from './appInsights/AppInsightsWrapper';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { apis } from './apis';
import { Routes as RoutesPath } from './constants/routes';
import { swissReTheme } from './theme/swissre';
import { PageLayout } from './layout/PageLayout';
import { Home } from './pages/Home';
import { Solutions } from './pages/Solutions';
import { Subscriptions } from './pages/Subscriptions';
import { ApiAnalytics } from './pages/ApiAnalytics';
import { Guides } from './pages/Guides';
import { Guide } from './pages/Guide';
import { SwaggerInfo } from './pages/Swagger';
import { SolutionDetails } from './pages/SolutionDetails';
import { PageNotFound } from './pages/PageNotFound';
import { SignedOut } from './pages/SignedOut';
import { ProtectedRoute } from './components/ProtectedRoute';
import { 
  AuthProvider,
  LoadingProvider,
  FeatureFlagsProvider,
  DialogProvider
} from './hooks/context';
import { SubscriptionData } from './pages/Subscriptions/components/SubscriptionData';

const devPortalApp = createApp({
  apis,
  components: {
    NotFoundErrorPage: PageNotFound,
  },
  themes: [
    {
      id: 'swissre-theme',
      title: 'SwissRe Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={swissReTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />;
    <Route path={RoutesPath.HOME} element={<Home />} />
    <Route path={RoutesPath.SIGNED_OUT} element={<SignedOut />} />
    <Route
      path={RoutesPath.SOLUTIONS}
      element={
        <ProtectedRoute>
          <Solutions />
        </ProtectedRoute>
      }
    />
    <Route
      path={RoutesPath.SOLUTION}
      element={
        <ProtectedRoute>
          <SolutionDetails />
        </ProtectedRoute>
      }
    />
    <Route
      path={RoutesPath.SUBSCRIPTIONS}
      element={
        <ProtectedRoute>
          <Subscriptions />
        </ProtectedRoute>
      }
    >
      <Routes>
        <Route path={RoutesPath.SUBSCRIPTION} element={<SubscriptionData />} />
      </Routes>
    </Route>
    <Route
      path={RoutesPath.API_ANALYTICS}
      element={
        <ProtectedRoute>
          <ApiAnalytics />
        </ProtectedRoute>
      }
    />
    <Route
      path={RoutesPath.GUIDES}
      element={
        <ProtectedRoute>
          <Guides />
        </ProtectedRoute>
      }
    />
    <Route
      path={RoutesPath.GUIDE}
      element={
        <ProtectedRoute>
          <Guide />
        </ProtectedRoute>
      }
    />
    <Route
      path={RoutesPath.SWAGGER}
      element={
        <ProtectedRoute>
          <SwaggerInfo />
        </ProtectedRoute>
      }
    />
  </FlatRoutes>
);

export default devPortalApp.createRoot(
  <>
    <AppInsightsWrapper>
      <AppRouter>
        <AuthProvider>
          <LoadingProvider>
            <FeatureFlagsProvider>
              <DialogProvider>
                <PageLayout>{routes}</PageLayout>
              </DialogProvider>
            </FeatureFlagsProvider>
          </LoadingProvider>
        </AuthProvider>
      </AppRouter>
    </AppInsightsWrapper>
  </>,
);
