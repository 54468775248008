import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Routes } from '../../constants/routes';
import { useAuth } from '../../hooks';
import Logo from '../../components/Logo';
import { SignIn } from '../../components/SignIn';
import { useStyles } from './styles';

export const Header: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { user } = useAuth();
  const { t } = useTranslation();

  return (
    <header className={classes.header}>
      <Box className={classes.leftItemsBox}>
        <Link className={classes.logo} to={Routes.HOME} component={RouterLink} aria-label='SwissRe logo'>
          <Logo />
          {matches && <Typography className={classes.logoTitle}>Developer Portal</Typography>}
        </Link>
        {user && (
          <>
            <Link className={classes.navLink} to={Routes.SOLUTIONS} component={RouterLink}>
            {t('eapi-solutions-text')}
            </Link>
            <Link className={classes.navLink} to={Routes.SUBSCRIPTIONS} component={RouterLink}>
              {t('eapi-subscriptions-text')}
            </Link>
            <Link className={classes.navLink} to={Routes.API_ANALYTICS} component={RouterLink}>
            {t('eapi-analytics-text')}
            </Link>
            <Link className={classes.navLink} to={Routes.GUIDES} component={RouterLink}>
            {t('eapi-guides-text')}
            </Link>
          </>
        )}
      </Box>
      <Box className={classes.rightItemsBox}>
        <SignIn />
      </Box>
    </header>
  );
};
