export enum FieldLabels {
  BUSINESS_EMAIL = 'eapi-business-email-text',
}

export enum FieldNames {
  BUSINESS_EMAIL = 'businessEmail',
}

export interface MembersFormData {
  [FieldNames.BUSINESS_EMAIL]: string;
}