import React, { ChangeEvent, FC } from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { Solution } from '@internal/plugin-eapi-common';
import { useTranslation } from 'react-i18next';
interface SolutionsFilterProps {
  solutions: Solution[];
  selectedSolution: Solution;
  onSolutionChange: (solution: Solution) => void;
}

export const SolutionsFilter: FC<SolutionsFilterProps> = ({ solutions = [], onSolutionChange, selectedSolution }) => {

  const { t } = useTranslation();
  
  const handleSolutionChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    const selectedSolutionName = event.target.value as string;
    const selectedSolution = solutions.find((solution) => solution.name === selectedSolutionName);

    selectedSolution && onSolutionChange(selectedSolution);
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel id="solutions-filter-label">{t('eapi-solution-text')}</InputLabel>
      <Select
        labelId="solutions-filter-label"
        id="solution-filter-select"
        value={selectedSolution.name}
        onChange={handleSolutionChange}
        fullWidth
        disabled={solutions.length === 1}
        disableUnderline
      >
        {solutions.map((solution) => (
          <MenuItem key={solution.name} value={solution.name}>
            {solution.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
