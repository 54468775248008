import React, { FC } from 'react';
import { Consumption } from '@internal/plugin-eapi-common';
import { PieChart } from '@mui/x-charts';
import { PieCenterLabel } from '../PieCenterLabel';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface ConsumptionProps {
  data: Consumption;
}

export const ConsumptionView: FC<ConsumptionProps> = ({ data }) => {
  const { t } = useTranslation();
  
  const creditLimit = data?.creditLimit;
  const overall = data?.overall;
  const isLimited = !!creditLimit;

  return isLimited ? (
    <PieChart
      colors={['#D8D9DC', '#00AA91']}
      series={[{ data: [{ value: creditLimit - overall }, { value: overall }], innerRadius: 60 }]}
      height={220}
      margin={{ right: 5 }}
    >
      <PieCenterLabel labelValue={(overall * 100) / creditLimit} />
    </PieChart>
  ) : (
    <Box
      height="220px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={2}
      border="1px solid #00AA91"
      color="#00AA91"
    >
      <Typography variant="h3">{overall}</Typography>
      <Typography variant="body2">{t('eapi-used-text')}</Typography>
    </Box>
  );
};
