import React, { FC, PropsWithChildren, useCallback, useEffect } from 'react';
import { useAuth, useIsMounted, useLoading, useRefreshToken } from '../../hooks';
import { LinearProgress } from '@material-ui/core';
import { LanguageProvider } from '../../hooks/context';

export const PersistLogin: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const { refresh } = useRefreshToken();
  const { loading, setLoading } = useLoading();
  const isMounted = useIsMounted();

  const onTranslationsLoaded = useCallback(() => setLoading(false), [setLoading]);

  useEffect(() => {
    const verifyAuthStatus = async () => {
      try {
        setLoading(true);
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };
    !user?.identity.token ? verifyAuthStatus() : setLoading(false);
  }, []);

  if (loading) {
    return <LinearProgress data-testid="linear-progress" />;
  }

  if (!user) return children;

  return (
    <LanguageProvider onTranslationsLoaded={onTranslationsLoaded}>
      {children}
    </LanguageProvider>
  );
};
