import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react';
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import { TimeUnitRangeValue } from '@internal/plugin-eapi-common';
import { TimePeriodOption } from './types';
import { useTranslation } from 'react-i18next';

export interface TimePeriodFilterProps {
  defaultValue: TimePeriodOption<TimeUnitRangeValue>['value'];
  setFilter: Dispatch<SetStateAction<TimeUnitRangeValue>>
  options: TimePeriodOption<TimeUnitRangeValue>[];
}

export const TimePeriodFilter: FC<TimePeriodFilterProps> = ({ defaultValue, setFilter, options }) => {

  const { t } = useTranslation();
  
  const handleFilterChange = (e: ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value;
    setFilter(value as TimeUnitRangeValue);
  };

  return (
    <FormControl variant="standard" fullWidth >
      <InputLabel htmlFor="time-period-filter-label">{t('eapi-period-text')}</InputLabel>
      <Select labelId="time-period-filter-label" defaultValue={defaultValue} onChange={handleFilterChange} fullWidth disableUnderline>
        {options.map((option) => (
          <MenuItem value={option.value} key={option.name}>
            {t(option.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
