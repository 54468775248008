import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useAsync } from 'react-use';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { ImageMessage } from '../../components/ImageMessage';
import { useEAPIAssetsApi } from '../../hooks';
import { ContainerName } from '@internal/plugin-eapi-common';
import { TechDocsHTML } from '../../components/TechDocsHTML';
import { EAPITechDocsMetadata, extractMetadata } from '@internal/plugin-eapi-react';

export const Guide = () => {
  const { pathname } = useLocation();
  const eapiAssetsApi = useEAPIAssetsApi();
  const [techDocsMetadata, setTechDocsMetadata] = useState<EAPITechDocsMetadata>();
  const { t } = useTranslation();
  const strippedPathName = pathname.split('/').pop();

  const {
    value: content,
    loading,
    error,
  } = useAsync(async () => {
    const name = `eapi-${strippedPathName}`;
    const dir = 'dev-portal';
    const content = await eapiAssetsApi.fetchFile<string>(ContainerName.EAPI_TECH_DOCS, `default/${dir}/${name}/index.html`);
    const { cssFileName, title, description } = extractMetadata(content);

    const metadata: EAPITechDocsMetadata = {
      fileName: name || '',
      cssFileName: cssFileName || '',
      title: title || '',
      description: description || '',
      //using dir as a kind here to align to the way techdocs cli publishes docs
      kind: dir
    };

    setTechDocsMetadata(metadata);
    return content;
  }, [pathname]);

  if (error) {
    return (
      <Page>
        <ImageMessage type="error" title={t('eapi-guide-error')} />
      </Page>
    );
  }

  return (
    <Page title={t(`eapi-guide-${strippedPathName}-title-text`)} description={t(`eapi-guide-${strippedPathName}-description-text`)}>
      {loading ? <LinearProgress /> : techDocsMetadata && <TechDocsHTML content={content || ''} metadata={techDocsMetadata} />}
    </Page>
  );
};
