import React, { FC } from 'react';
import { Box, Typography } from '@material-ui/core';
import { SignInButton } from '../../components/SignInButton';
import { Navigate } from 'react-router';
import { Page } from '../../layout/Page';
import { useStyles } from './styles';
import { LogOutModeType } from '../../constants/auth';
import { useTranslation } from 'react-i18next';

export const SignedOut: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const mode = window.localStorage.getItem('eapi-logout-mode');
  const isAutoMode = mode && mode === LogOutModeType.AUTO;
  window.localStorage.clear();
  if (isAutoMode) {
    return (
      <Page>
        <Box textAlign="center">
          <Typography variant="overline">{t('eapi-timeout-notify-text', {defaultValue: 'Timeout notification'})}</Typography>
          <Typography variant="h3">{t('eapi-signed-out-text', {defaultValue: 'You have been signed out'})}</Typography>
          <Typography>{t('eapi-signed-out-info', {defaultValue: 'You were automatically signed out after a period of inactivity on the Developer Portal.'})}</Typography>
          <SignInButton className={classes.button} variant="contained">
            {t('eapi-log-back-in-text', {defaultValue: 'Log back in'})}
          </SignInButton>
        </Box>
      </Page>
    );
  } else {
    return <Navigate to="/" />;
  }
};