import React, { FC, MouseEvent, memo } from 'react';
import { ToggleButton as MUIToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useStyles } from './styles';
import { useTranslation } from 'react-i18next';

export enum ToggleButtonValue  {
  ACTIVITY = 'activity',
  CONSUMPTION = 'consumption',
};
interface ToggleButtonProps {
  value: ToggleButtonValue;
  onChange: (_: MouseEvent<HTMLElement>, newValue: ToggleButtonValue) => void;
}

export const ToggleButton: FC<ToggleButtonProps> = memo(({ value, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ToggleButtonGroup className={classes.buttonGroup} value={value} onChange={onChange} exclusive>
      <MUIToggleButton className={classes.button} value={ToggleButtonValue.ACTIVITY}>
        {t('eapi-activity-text')}
      </MUIToggleButton>
      <MUIToggleButton className={classes.button} value={ToggleButtonValue.CONSUMPTION}>
        {t('eapi-consumption-text')}
      </MUIToggleButton>
    </ToggleButtonGroup>
  );
});
