import React, { useState, MouseEvent } from 'react';
import { IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useLogout } from "../../../../hooks";
import { LogOutModeType } from '../../../../constants/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
  itemText: {
    fontSize: "16px",
    fontWeight: 400
  },
  itemIcon: {
    minWidth: 30
  }
});

export const ProfileMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useLogout();
  const { t } = useTranslation();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-controls="profile-menu" aria-haspopup="true" onClick={handleClick}>
        <ExpandMoreIcon />
      </IconButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => logout(LogOutModeType.MANUAL)}>
          <ListItemIcon className={classes.itemIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText className={classes.itemText} disableTypography primary={t('eapi-logout-text')} />
        </MenuItem>
      </Menu>
    </div>
  );
}