import jwt_decode from 'jwt-decode';
import { Language } from '@internal/plugin-eapi-common';

export function getUserLocale(oktaAccessToken: string): string {
  const decodedToken = jwt_decode<{ locale: Language }>(oktaAccessToken);
  const locale = decodedToken.locale ? decodedToken.locale : Language.EN_US;
  return normalizeLocale(locale);
}

function normalizeLocale(locale: string) {
  return locale
    .toLowerCase()
    .replace('_', '-')
    .replace(/([a-z]+)-([a-z]+)/, (_match, p1, p2) => p1 + '-' + p2.toUpperCase());
}