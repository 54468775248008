import * as yup from 'yup';
import { TFunction } from 'i18next';
import { FieldLabels, FieldNames } from './constants';
import { EMAIL_REGEX, NO_HTML_REGEX, createErrorMessageGetter } from '../../../../../../../../constants/validation';

export const createSchema = (t: TFunction<'translation', undefined>) => {
  const getErrorMessage = createErrorMessageGetter(t);

  return yup.object().shape({
    [FieldNames.BUSINESS_EMAIL]: yup
      .string()
      .required(getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'required'))
      .matches(EMAIL_REGEX, getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'email'))
      .matches(NO_HTML_REGEX, getErrorMessage(FieldLabels.BUSINESS_EMAIL, 'html'))
      .transform((value) => value.trim()),
  });
};
