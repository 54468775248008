import React, { FC, useCallback, useMemo, useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAsyncRetry } from 'react-use';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useEncryptionApi } from '../../../../../../../../hooks';
import { UploadKeyModal } from './components/UploadKeyModal';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { useStyles } from './styles';

export const AsymmetricKeys: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const encryptionApi = useEncryptionApi();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationType, setNotificationType] = useState<'error' | 'success'>('success');
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const { clientId } = useParams();

  if (!clientId) return;

  const { value, loading, error, retry } = useAsyncRetry(async () => await encryptionApi.getPublicKeys(clientId), [clientId]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = useCallback(() => setIsModalOpen(false), []);

  const handleUploadKey = useCallback(
    (type: 'success' | 'error', message: string) => {
      setNotificationOpen(true);
      setNotificationType(type);
      setNotificationMessage(message);

      if (type === 'success' && !loading) {
        retry();
      }
    },
    [loading, retry],
  );

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationType('success');
    setNotificationMessage('');
  };

  useEffect(() => {
    if (!error) return;

    setNotificationOpen(true);
    setNotificationType('error');
    setNotificationMessage(error.message);
  }, [error]);

  const publicKey: ReactElement = useMemo(() => {
    if (!value) return <Typography>{t('eapi-not-available-text')}</Typography>;

    const { key } = value;

    return key ? <code className={classes.key}>{key}</code> : <Typography>{t('eapi-cred-asym-empty-state-text')}</Typography>;
  }, [value]);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('eapi-cred-asym-title-text')}
      </Typography>
      <Box mb={2} py={1}>
        {loading ? <CircularProgress data-testid="circular-progress" size={20} /> : publicKey}
      </Box>
      <Button color="default" startIcon={<CloudUploadIcon />} onClick={handleOpenModal}>
        {t('eapi-cred-asym-upload-text')}
      </Button>
      <UploadKeyModal clientId={clientId} open={isModalOpen} onModalClose={handleCloseModal} onUploadKey={handleUploadKey} />
      <NotificationSnackBar
        open={notificationOpen}
        onClose={handleNotificationClose}
        message={notificationMessage}
        type={notificationType}
      />
    </>
  );
};
