import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { KeyStatus as KeyStatusEnum } from '@internal/plugin-eapi-common';
import { useStyles } from './styles';

export interface KeyStatusProps {
  status: KeyStatusEnum;
}

export const KeyStatus: FC<KeyStatusProps> = ({ status }) => {
  const classes = useStyles({ status });
  const { t } = useTranslation();

  const STATUS_TEXT_MAP = {
    [KeyStatusEnum.Active]: t("eapi-active-text"),
    [KeyStatusEnum.Inactive]: t( "eapi-inactive-text"),
  } as const;

  return (
    <Box display="flex" alignItems="center" gridColumnGap={6}>
      <span className={classes.indicator}></span>
      {STATUS_TEXT_MAP[status]}
    </Box>
  );
}