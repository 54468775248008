import React, { useEffect, useState } from 'react';
import { Entity } from '@backstage/catalog-model';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LinearProgress, Grid } from '@material-ui/core';
import { Page } from '../../layout/Page';
import { SolutionCard } from './components/SolutionCard';
import { AudienceType } from '@internal/plugin-eapi-common';
import { useTranslation } from 'react-i18next';
import { useCatalogApi } from '../../hooks';

export const Solutions = () => {
  const [entities, setEntities] = useState<Entity[]>();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<Error>();
  const { getEntities } = useCatalogApi();
  const { t } = useTranslation();

  const AUDIENCE_KEY = 'swissre.com/audience';

  useEffect(() => {
    const appliedFilter: Record<string, string> = {
      kind: 'system',
    };
    appliedFilter[`metadata.annotations.${AUDIENCE_KEY}`] = AudienceType.EXTERNAL;

    setLoading(true);
    getEntities(appliedFilter)
      .then(({ items }) => setEntities(items))
      .catch((error) => {
        setError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const isEntitiesExist = entities && !!entities.length;

  return (
    <Page
      title={t('eapi-solutions-title-text')}
      subtitle={t('eapi-solutions-subtitle-text')}
    >
      {loading && <LinearProgress />}
      {error && (
        <Alert severity="error">
          <AlertTitle>{t('eapi-solutions-error')}</AlertTitle>
          {error.message}
        </Alert>
      )}
      {isEntitiesExist && (
        <Grid container spacing={3}>
          {entities
            .sort((a: Entity, b: Entity) => a.metadata.name.localeCompare(b.metadata.name))
            .map(({ metadata: { name, title, uid, annotations } }) => (
            <Grid xs={12} sm={6} md={4} lg={3} item key={uid}>
              <SolutionCard
                title={title || name}
                description={annotations?.['swissre.com/tag-line'] ?? ''}
                href={`${name}/overview`}
                name={name}
                imageName={annotations?.['swissre.com/image-name']}
                altText={title || name}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {!loading && !error && !isEntitiesExist && <Alert severity="warning">{t('eapi-solutions-warning')}</Alert>}
    </Page>
  );
};
