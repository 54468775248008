import React, { FC } from 'react';
import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import { getEntityTitle } from '@internal/plugin-eapi-common';
import {
  Grid,
  CardContent,
  Card,
  makeStyles,
  Typography,
  LinearProgress,
} from '@material-ui/core';
import { useEAPIRelatedEntities } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

export interface TechServicesCardProps {
  entity: Entity;
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    minHeight: 143,
  },
  cardTitle: {
    padding: '10px 16px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const TechServicesCards: FC<TechServicesCardProps> = ({ entity }) => {
  const classes = useStyles();
  const { entities, loading, error } = useEAPIRelatedEntities(entity, {
    type: RELATION_HAS_PART,
    kind: 'api',
  });
  const { t } = useTranslation();

  if (loading) {
    return <LinearProgress />;
  }

  if (error || !entities) return null;

  return (
    <>
      {!!entities.length && (
        <Typography variant="h3" className={classes.title}>
          {t('eapi-solution-title-text')}
        </Typography>
      )}
      <Grid container spacing={3}>
        {entities.map((entity) => (
          <Grid xs={12} sm={6} md={4} lg={3} item key={entity.metadata.name}>
            <Card>
              <Typography variant="h5" className={classes.cardTitle}>
                {getEntityTitle(entity)}
              </Typography>
              <CardContent className={classes.cardContent}>
                <Typography variant="body1">
                  {entity.metadata.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
