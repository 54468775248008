import { SDSEnvironmentType, Solution } from "@internal/plugin-eapi-common";
import { formatDate } from "@internal/plugin-eapi-react";

export const getAlertContent = (t: (key: string) => string, environmentType: SDSEnvironmentType, isTrial?: boolean, selectedSolution?: Solution,  ) => {
  
  const isProd = environmentType === SDSEnvironmentType.PROD;
  const title = isProd && !isTrial ? t('eapi-note-text') : t('eapi-notes-text');
  const defaultMessage = selectedSolution ? [`${t('eapi-analytics-consump-info')} ${formatDate(selectedSolution.dateTime, { dateStyle: 'long'},)}`] : []
  const message = isProd && !isTrial ? defaultMessage : [...defaultMessage, t('eapi-analytics-consump-trial-info')]
  
  return {title, message}
}