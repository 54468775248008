import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import { CircularProgress, Typography, Tooltip, IconButton, Box } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityIconOff from '@material-ui/icons/VisibilityOff';
import { useEncryptionApi } from '../../../../../../../../hooks';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { useParams } from 'react-router';
import { useStyles } from './styles';
import { AxiosError } from 'axios';
import { EAPI_GENERIC_ERROR_KEY } from '@internal/plugin-eapi-common';
import { useTranslation } from 'react-i18next';

//TODO - Temporary solution, to fallback to old UI until all subscriptions gets migrated to ECSP
export const LegacySymmetricKeys: FC = () => {
  const { clientId } = useParams();
  const encryptionApi = useEncryptionApi();
  const classes = useStyles();
  const { t } = useTranslation();

  const [notificationType, setNotificationType] = useState<'error' | 'success'>('success');
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [showSecret, setShowSecret] = useState<boolean>(false);

  const { value, loading, error } = useAsync(async () => {
    if (!clientId) return;

    return await encryptionApi.getLegacySecret(clientId);
  }, [clientId]);

  useEffect(() => {
    if (!error) return;

    setNotificationOpen(true);
    setNotificationType('error');
    if(error instanceof AxiosError){
      setNotificationMessage(t(error.response?.data.message) || t(EAPI_GENERIC_ERROR_KEY));
    } else {
      setNotificationMessage(t(error.message));
    }
  }, [error]);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationType('success');
    setNotificationMessage('');
  };

  const maskedClientSecretKey = useMemo(() => value && value.clientSecret?.replace(/./g, '*'), [value]);

  const handleToggleVisibilityButton = () => setShowSecret((showSecret) => !showSecret);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        {t('eapi-cred-sym-title-text')}
      </Typography>
      {loading ? (
        <Box py={2}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <Box display="flex" gridGap={4} alignItems="center">
          {value ? (
            <>
              <code className={classes.key}>
                {showSecret ? value.clientSecret : maskedClientSecretKey}
              </code>
              <Tooltip title={showSecret ? t('eapi-hide-secret-text') : t('eapi-show-secret-text')} placement="top">
                <IconButton onClick={handleToggleVisibilityButton}>
                  {showSecret ? <VisibilityIcon /> : <VisibilityIconOff />}
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Typography>{t('eapi-not-available-text')}</Typography>
          )}
        </Box>
      )}
      <NotificationSnackBar
        open={notificationOpen}
        onClose={handleNotificationClose}
        message={notificationMessage}
        type={notificationType}
      />
    </>
  );
};

LegacySymmetricKeys.displayName = 'LegacySymmetricKeys';