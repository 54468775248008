import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { ApiRow } from '../ApiRow';
import { Api } from '@internal/plugin-eapi-common';
import { useStyles } from './styles';

export interface ApisTableProps {
  apis: Api[];
  handleSwaggerOpen: (uuid: string) => void;
  handleSwaggerDownload: (uuid: string) => void;
  postmanCollectionPath?: string;
}

export const ApisTable: FC<ApisTableProps> = memo(
  ({ apis, handleSwaggerOpen, handleSwaggerDownload, postmanCollectionPath }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    if (!apis) return null;

    return (
      <Table className={classes.table} size="medium" aria-label="Apis">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>{t("eapi-api-name-text")}</TableCell>
            <TableCell>{t("eapi-version-text")}</TableCell>
            <TableCell>{t("eapi-env-text")}</TableCell>
            <TableCell>{t("eapi-actions-text")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {apis.map(api => (
            <ApiRow
              key={api.uuid}
              api={api}
              handleSwaggerOpen={handleSwaggerOpen}
              handleSwaggerDownload={handleSwaggerDownload}
              postmanCollectionPath={postmanCollectionPath}
            />
          ))}
        </TableBody>
      </Table>
    );
  },
);

ApisTable.displayName = 'ApisTable';
