import React, { memo, FC } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@material-ui/core';
import { FieldLabels, FieldNames, SignUpFormData } from '../../constants';

export interface PersonalDetailsProps {
  control: Control<SignUpFormData>;
  errors: FieldErrors<SignUpFormData>
}

export const PersonalDetails: FC<PersonalDetailsProps> = memo(({ control, errors }) => {
  const { t } = useTranslation();
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name={FieldNames.FIRST_NAME}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              label={t(FieldLabels.FIRST_NAME)}
              type="text"
              margin="normal"
              required
              error={Boolean(errors[FieldNames.FIRST_NAME])}
              helperText={errors[FieldNames.FIRST_NAME]?.message}
              inputProps={{ 'aria-label': FieldLabels.FIRST_NAME }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          control={control}
          name={FieldNames.LAST_NAME}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              label={t(FieldLabels.LAST_NAME)}
              type="text"
              margin="normal"
              required
              error={Boolean(errors[FieldNames.LAST_NAME])}
              helperText={errors[FieldNames.LAST_NAME]?.message}
              inputProps={{ 'aria-label': FieldLabels.LAST_NAME }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name={FieldNames.BUSINESS_EMAIL}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              label={t(FieldLabels.BUSINESS_EMAIL)}
              type="email"
              margin="normal"
              required
              error={Boolean(errors[FieldNames.BUSINESS_EMAIL])}
              helperText={errors[FieldNames.BUSINESS_EMAIL]?.message}
              inputProps={{ 'aria-label': FieldLabels.BUSINESS_EMAIL }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
});

PersonalDetails.displayName = 'PersonalDetails';