import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { Box, IconButton, LinearProgress, Typography, makeStyles } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Page } from "../../layout/Page";
import { useDevPortalApi } from '../../hooks';
import { ImageMessage } from '../../components/ImageMessage';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5)
  },
}));

const InfoComponent = (props: any) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleGoBack = () => navigate(-1);

  return (
    <Box>
      <Box display="flex" alignItems='center'>
        <IconButton onClick={handleGoBack}>
          <ArrowBackIcon fontSize="medium" />
        </IconButton>
        <Typography variant="h3">{props.info.get("title")}</Typography>
      </Box>
      <Typography variant="h4">
        {props.info.get("summary")}
      </Typography>
      <Typography variant="body1" className={classes.description}>
        {props.info.get("description")}
      </Typography>
    </Box>
  );
};

const SwaggerCustomInfo = () => {
  return {
    wrapComponents: {
      info: () => (props: any) => {
        return <InfoComponent {...props} />;
      },
    },
  };
}

export const SwaggerInfo: FC = () => {
  const search = new URLSearchParams(window.location.search);
  const uuid = search.get('uuid') as string;
  const devPortalApi = useDevPortalApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [swaggerDetails, setSwaggerDetails] = useState<string>();

  useEffect(() => {
    async function fetchSwaggerInfoUrl() {
      setLoading(true)
      await devPortalApi.getSwaggerInfo(uuid)
        .then(data => {
          setSwaggerDetails(data);
        })
        .catch(error => {
          setError(error);
          console.error('Swagger Info call error ', error)
        })
        .finally(() => {
          setLoading(false)
        })
    }

    fetchSwaggerInfoUrl();
  }, []);

  if (error) {
    const messageTitle = ((error instanceof AxiosError) && error.response?.status === 403)
      ? t('eapi-swagger-info')
      : t('eapi-swagger-error')
    return (
      <Page>
        <ImageMessage type="error" title={messageTitle} />
      </Page>
    );
  }

  return (
    <Page>
      {loading && <LinearProgress />}
      {!error && !loading &&
        <SwaggerUI
          spec={swaggerDetails}
          plugins={[SwaggerCustomInfo]}
          supportedSubmitMethods={[]} />
      }
    </Page>
  );
}
