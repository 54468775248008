import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid, Table, TableBody, TableCell, TableRow, TableHead, IconButton, Tooltip } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { AuthenticationType } from '@internal/plugin-eapi-common';
import { useSubscription } from '../../../../../../hooks';
import { Routes } from '../../../../../../constants/routes';
import { GuidesNames } from '../../../../../../constants/guides';
import { LinksBox } from './components/LinksBox';
import { AsymmetricKeys } from './components/AsymmetricKeys';
import { SymmetricKeys } from './components/SymmetricKeys';
import { LinkDetails } from './types';
import { AlertBar } from '../../../../../../components/AlertBar';
import { useGuidesList } from '../../../../../../hooks';
import { useStyles } from './styles';
import { LegacySymmetricKeys } from './components/LegacySymmetricKeys';

export const CredentialsTab: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { subscription } = useSubscription();
  const { guidesList } = useGuidesList();
  const [toolTipText, setToolTipText] = useState([] as boolean[]);
  if (!subscription) return null;

  const {
    subscriptionDetails: { clientId, authorizationType, connectedApis, isLegacy },
  } = subscription;

  const copiedText = t('eapi-copied-text');
  const copyText = t('eapi-copy-text');

  const setTooltipCopied = (index: number) => {
    let tmp = [...toolTipText];
    tmp[index] = false;
    setToolTipText(tmp);
  };

  const clearTooltipCopied = (index: number) => {
    let tmp = [...toolTipText];
    tmp[index] = true;
    setToolTipText(tmp);
  };

  const isPublicKeyAuthType = authorizationType === AuthenticationType.PUBLIC_KEY;
  const isSecretAuthType = authorizationType === AuthenticationType.SECRET;

  const getKeysComponent = () => {
    if (isPublicKeyAuthType) return AsymmetricKeys;
    else if (isLegacy) return LegacySymmetricKeys;
    else if (isSecretAuthType) return SymmetricKeys;
    return null;
  };

  const KeysComponent = getKeysComponent();
  const alertMessage = t('eapi-cred-warning', { authType: isSecretAuthType ? 'client_secret_post' : 'private_key_jwt' });

  const getLinks = (): LinkDetails[] =>
    (guidesList || [])
      .filter(({ name }) =>
        isSecretAuthType
          ? name === GuidesNames.SharedSecretAuth
          : name === GuidesNames.KeyGenAuth || name === GuidesNames.KeyPairAuth,
      )
      .map(({ name }) => ({
        displayText: t(`eapi-guide-${name}-title-text`),
        path: `${Routes.GUIDES}/${name}`,
      }));

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <AlertBar message={alertMessage} variant="base" />
      </Grid>
      {guidesList && (
        <Grid item xs={12}>
          <LinksBox links={getLinks()} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Box mb={5}>
          <Typography variant="h5" gutterBottom>
            {t('eapi-client-id-text')}
          </Typography>
          <Typography>{clientId}</Typography>
        </Box>
        {KeysComponent && <KeysComponent />}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          {t('eapi-cred-auth-details-text')}
        </Typography>
        {connectedApis.length ? (
          <Table className={classes.table} aria-label="authorization server details">
            <TableHead>
              <TableRow>
                <TableCell width={300}>{t('eapi-api-name-text')}</TableCell>
                <TableCell>{t('eapi-token-endpoint-text')}</TableCell>
                <TableCell>{t('eapi-actions-text')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectedApis.map(({ name, authorizationServerId }, index) => (
                <TableRow key={authorizationServerId}>
                  <TableCell component="th" className={classes.tableCellBody}>
                    {name}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    https://identity.swissre.com/oauth2/{authorizationServerId}
                    /v1/token
                  </TableCell>
                  <TableCell width={20} align="center" className={classes.tableCellBody}>
                    <Tooltip title={toolTipText[index] ? copyText : copiedText} placement="top">
                      <span>
                        <IconButton
                          aria-label="copy"
                          color="inherit"
                          onPointerLeave={() => {
                            setTimeout(() => {
                              clearTooltipCopied(index);
                            }, 700);
                          }}
                          onPointerEnter={() => {
                            clearTooltipCopied(index);
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(`https://identity.swissre.com/oauth2/${authorizationServerId}/v1/token`);
                            setTooltipCopied(index);
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography color="textSecondary">{t('eapi-apis-empty-state-text')} No APIs available</Typography>
        )}
      </Grid>
    </Grid>
  );
};
