import { TFunction } from 'i18next';
import { TableColumn } from '@backstage/core-components';
import { ApiHitActivity } from '@internal/plugin-eapi-common';
import { formatDateToUTC } from '@internal/plugin-eapi-react';
import { Options } from '@material-table/core';

export const getColumns = (t: TFunction<'translation', undefined>, language?: string): TableColumn<ApiHitActivity>[] => [
  {
    field: 'timestamp',
    title: t('eapi-analytics-activ-column-date-time-text'),
    render: ({ timestamp }) => formatDateToUTC(timestamp, language),
  },
  {
    field: 'apiName',
    title: t('eapi-analytics-activ-column-name-text'),
    cellStyle: { minWidth: '200px' },
  },
  {
    field: 'requestMethod',
    title: t('eapi-analytics-activ-column-method-text'),
    width: 'auto',
  },
  {
    field: 'clientRequestPath',
    title: t('eapi-analytics-activ-column-req-path-text'),
  },
  {
    field: 'edgeResponseStatus',
    title: t('eapi-analytics-activ-column-status-text'),
  },
  {
    field: 'apmId',
    title: t('eapi-analytics-activ-column-apm-id-text'),
  },
  {
    field: 'clientIP',
    title: t('eapi-analytics-activ-column-client-ip-text'),
  },
  {
    field: 'clientRequestHost',
    title: t('eapi-analytics-activ-column-client-req-host-text'),
  },
  {
    field: 'clientRequestURI',
    title: t('eapi-analytics-activ-column-client-req-uri-text'),
  },
  {
    field: 'contentType',
    title: t('eapi-analytics-activ-column-content-type-text'),
  },
  {
    field: 'clientId',
    title: t('eapi-analytics-activ-column-consumer-id-req-uri-text'),
  },
];

export const options: Options<ApiHitActivity> = {
  paging: true,
  pageSize: 20,
  headerStyle: { textTransform: 'none', whiteSpace: 'nowrap', borderTop: 'none' },
  rowStyle: (_, index) => ({
    backgroundColor: index % 2 === 0 ? '#F9F9FA' : '#FFFFFF',
    verticalAlign: 'top',
  }),
};
