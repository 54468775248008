import React, {FC, ReactElement} from "react";
import {Box, Link, makeStyles, Typography} from "@material-ui/core";
import {Links} from "../../constants/links";
import errorImage from "../../assets/states/error.svg";
import emptyContentImage from "../../assets/states/empty-content.svg";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  image: {
    display: "inline-block",
    marginBottom: theme.spacing(7),
    boxShadow: "0px 56px 60px -9px rgba(60, 65, 80, 0.06)," +
        "0px 29.3375px 26.1562px -9px rgba(60, 65, 80, 0.0405)," +
        "0px 12.8px 9.75px -9px rgba(60, 65, 80, 0.03)," +
        "0px 4.3625px 3.46875px -9px rgba(60, 65, 80, 0.0195);"
  },
  title: {
    marginBottom: theme.spacing(3)
  }
}))

export interface ImageMessageProps {
  title: string,
  type: "error" | "info",
  children?: ReactElement
}


export const ImageMessage: FC<ImageMessageProps> = ({ type, title, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isErrorType = type === "error";
  const imageHref = isErrorType ? errorImage : emptyContentImage;
  const mailTo = `mailto:developer_portal@swissre.com?subject=${t('eapi-subscriptions-error')}`

  return (
      <Box
          textAlign="center"
          px={2}
          py={3}
          data-testid='image-message'
      >
        <img className={classes.image} src={imageHref} alt={`${type} message image`} />
        <Typography variant="h4" className={classes.title}>{title}</Typography>
          {children}
        <Typography>{isErrorType ? t('eapi-error-info'): t('eapi-technical-error')}
          <br />
          <Link underline="always" href={Links.SERVICE_NOW}>{t('eapi-support-portal-text')}</Link>
          &nbsp;{t('eapi-or-our-email-text')}&nbsp;
          <Link
              underline="always"
              href={mailTo}
          >
            developer_portal@swissre.com
          </Link>
        </Typography>
      </Box>
  )
}