import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Table, TableBody, TableCell, TableRow, TableHead } from '@material-ui/core';
import { useSubscription } from '../../../../../../hooks';
import { useStyles } from './styles';

export const DetailsTab: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { subscription } = useSubscription();

  if (!subscription) return null;

  const {
    subscriptionDetails: { connectedApis },
  } = subscription;

  return (
    <>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          {t('eapi-subs-details-title-text')}
        </Typography>
        {connectedApis.length ? (
          <Table className={classes.table} aria-label="connected APIs">
            <TableHead>
              <TableRow>
                <TableCell>{t('eapi-product-api-name-text')} </TableCell>
                <TableCell> {t('eapi-bs-name-text')} </TableCell>
                <TableCell> {t('eapi-apm-id-text')} </TableCell>
                <TableCell> {t('eapi-auth-server-id-text')} </TableCell>
                <TableCell> {t('eapi-environment-text')} </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {connectedApis.map(({ name, businessServiceName, apmId, authorizationServerId, environmentType }) => (
                <TableRow key={authorizationServerId}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{businessServiceName}</TableCell>
                  <TableCell>{apmId}</TableCell>
                  <TableCell>{authorizationServerId}</TableCell>
                  <TableCell>{environmentType}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography color="textSecondary"> {t('eapi-not-available-text')}</Typography>
        )}
      </Box>
    </>
  );
};
