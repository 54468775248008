import React, { FC, useState } from 'react';
import { useAsync } from 'react-use';
import { useParams, useSearchParams } from 'react-router-dom';
import { LinearProgress } from '@material-ui/core';
import { ContainerName } from '@internal/plugin-eapi-common';
import { EAPITechDocsMetadata, extractMetadata, Kinds } from '@internal/plugin-eapi-react';
import { useEAPIAssetsApi } from '../../hooks';
import { ImageMessage } from '../ImageMessage';
import { TechDocsHTML } from '../TechDocsHTML';
import { useTranslation } from 'react-i18next';

export const ServiceDocumentationTab: FC = () => {
  const eapiAssetsApi = useEAPIAssetsApi();
  const { t } = useTranslation();
  const [techDocsMetadata, setTechDocsMetadata] = useState<EAPITechDocsMetadata>();

  const { solutionName }= useParams();
  const [searchParams] = useSearchParams();
  const docType = searchParams.get('docType');

  const {
    value: content,
    loading,
    error,
  } = useAsync(async () => {
    const name = docType ? `${solutionName}-${docType}` : solutionName;
    const kind = Kinds.SYSTEM;
    const content = await eapiAssetsApi.fetchFile<string>(ContainerName.EAPI_TECH_DOCS, `default/${kind}/${name}/index.html`);
    const {cssFileName, title, description} = extractMetadata(content);

    const metadata: EAPITechDocsMetadata = {
      fileName: name || '',
      cssFileName: cssFileName || '',
      title: title || '',
      description: description || '',
      kind: kind
    };
    
    setTechDocsMetadata(metadata);
    return content
  }, [solutionName, docType]);

  if (content?.length === 0 || error?.stack?.includes('422')) {
    return <ImageMessage type="info" title={t("eapi-doc-info")} />;
  } 

  if (error) {
    return <ImageMessage type="error" title={t("eapi-doc-error")} />;
  }

  if (loading) return <LinearProgress />;

  if(!techDocsMetadata) return null;

  return <TechDocsHTML content={content || ''} metadata={techDocsMetadata}/>;
};
