import React, { createContext, useContext, FC, PropsWithChildren, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { getUserLocale, Language } from '@internal/plugin-eapi-common';
import { useDevPortalApi } from '../../api';
import { useAuth } from '../useAuth';
import i18n from '../../../config/i18n';

interface ILanguageContext {
  language: Language;
}

interface LanguageProviderProps {
  onTranslationsLoaded: () => void;
}

const LanguageContext = createContext<ILanguageContext>({
  language: Language.EN_US,
});

export const LanguageProvider: FC<PropsWithChildren<LanguageProviderProps>> = ({ onTranslationsLoaded, children }) => {
  const devPortalApi = useDevPortalApi();
  const { user } = useAuth();
  const [language, setLanguage] = useState<Language>(Language.EN_US);

  useEffect(() => {
    const oktaAccessToken = user?.oktaAccessToken;
    if (!oktaAccessToken) return;

    const locale = getUserLocale(oktaAccessToken) as Language;
    setLanguage(locale);

    const loadTranslations = async () => {
      try {
        const translations = await devPortalApi.getTranslations();

        if (translations) {
          i18n.addResourceBundle(locale, 'translation', translations);
          i18n.reloadResources();
          await i18n.changeLanguage(locale);
        }
      } catch (error) {
        console.error('Error loading translations:', error);
      } finally {
        onTranslationsLoaded();
      }
    };

    loadTranslations();
  }, []);

  return (
    <LanguageContext.Provider value={{ language }}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
