import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Tooltip,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import WarningOutlineIcon from '@material-ui/icons/WarningOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityIconOff from '@material-ui/icons/VisibilityOff';
import { KeyStatus as KeyStatusEnum, SymmetricKey } from '@internal/plugin-eapi-common';
import { formatDateToUTC } from '@internal/plugin-eapi-react';
import { useLanguage } from '../../../../../../../../../../hooks';
import { KeyStatus } from './components/KeyStatus';
import { useStyles } from './styles';

export interface KeysListTableProps {
  keysData?: SymmetricKey[];
  onKeyDelete: (keyId: string) => () => void;
  isLoading: boolean;
  error?: Error;
}

// TODO: Better to use as a common for both Symmetric and Asymmetric keys.
// In this case this component needs to be moved to the upper common folder.
export const KeysListTable: FC<KeysListTableProps> = memo(({ keysData, onKeyDelete, isLoading, error }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useLanguage();
  const [showSecret, setShowSecret] = useState<boolean>(false);
  const isDisabledDeleteButton = (currentStatus: KeyStatusEnum) =>
    keysData?.length === 1 || (keysData?.some((key) => key.status === KeyStatusEnum.Inactive) && currentStatus === KeyStatusEnum.Active);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t("eapi-id-text")}</TableCell>
          <TableCell>{t("eapi-secret-text")}</TableCell>
          <TableCell>{t("eapi-status-text")}</TableCell>
          <TableCell>{t("eapi-creation-date-time-text")}</TableCell>
          <TableCell>{t("eapi-actions-text")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!keysData || keysData.length === 0 || isLoading ? (
          <TableRow>
            <TableCell colSpan={5} align="center" className={classes.spacing}>
              {isLoading ? (
                <CircularProgress data-testid="circular-progress" size={20} />
              ) : (
                <Typography variant="body2">
                  {t(error ? "eapi-encountered-error": "eapi-cred-sym-empty-state-text")}
                </Typography>
              )}
            </TableCell>
          </TableRow>
        ) : (
          keysData.map(({ id, createdAt, status, secret, isLegacy }) => (
            <TableRow key={id}>
              <TableCell>{id}</TableCell>
              <TableCell>
                <Box display="inline-flex" alignItems="center" gridColumnGap={8}>
                  <span className={classes.key}>{secret && showSecret ? secret : '***************************'}</span>
                  {isLegacy ? (
                    <Tooltip title={t("eapi-cred-sym-legacy-secret-info")} placement="top">
                      <WarningOutlineIcon className={classes.icon} fontSize="small" />
                    </Tooltip>
                  ) : (
                    <Tooltip title={t("eapi-cred-sym-secret-info")} placement="top">
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )}
                </Box>
              </TableCell>
              <TableCell>
                <KeyStatus status={status} />
              </TableCell>
              <TableCell>{formatDateToUTC(createdAt, language)}</TableCell>
              <TableCell width={isLegacy ? 130 : 50} align="left">
                <Tooltip
                  title={t(isDisabledDeleteButton(status) ? "eapi-cred-sym-secret-delete-info" : 'eapi-delete-text')}
                  placement="top"
                >
                  <span>
                    <IconButton disabled={isDisabledDeleteButton(status)} aria-label="delete" color="inherit" onClick={onKeyDelete(id)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {isLegacy && (
                  <span>
                    <Tooltip title={t(showSecret ? "eapi-hide-secret-text" : "eapi-show-secret-text")} placement="top">
                      <IconButton arial-label="visible" onClick={() => setShowSecret((showSecret) => !showSecret)}>
                        {showSecret ? <VisibilityIcon /> : <VisibilityIconOff />}
                      </IconButton>
                    </Tooltip>
                  </span>
                )}
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
});

KeysListTable.displayName = 'KeysListTable';
