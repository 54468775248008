import { FC } from 'react';
import { ApisTab } from './components/ApisTab';
import { CredentialsTab } from './components/CredentialsTab';
import { MembersTab } from './components/MembersTab';
import { DetailsTab } from './components/DetailsTab';

export interface TabConfig {
  panelComponent: FC;
  key: string;
  path: string;
  properties?: {
    serviceName?: string,
    docType?: string
  }
}

const TAB_KEYS = {
  ApisTab: 'eapi-apis-text',
  CredentialsTab: 'eapi-credentials-text',
  MembersTab: 'eapi-members-text',
  DetailsTab: 'eapi-subscription-details-text',
};

export const TAB_PATHS = {
  ApisTab: 'apis',
  CredentialsTab: 'credentials',
  MembersTab: 'members',
  DetailsTab: 'details',
};

export const TABS_CONFIGURATION: ReadonlyArray<TabConfig> = [
  {
    panelComponent: ApisTab,
    key: TAB_KEYS.ApisTab,
    path: TAB_PATHS.ApisTab,
  },
  {
    panelComponent: CredentialsTab,
    key: TAB_KEYS.CredentialsTab,
    path: TAB_PATHS.CredentialsTab,
  },
  {
    panelComponent: MembersTab,
    key: TAB_KEYS.MembersTab,
    path: TAB_PATHS.MembersTab,
  },
  {
    panelComponent: DetailsTab,
    key: TAB_KEYS.DetailsTab,
    path: TAB_PATHS.DetailsTab,
  },
];