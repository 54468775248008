import React, { memo, FC, useRef, Dispatch, SetStateAction, useMemo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Box, Button, CircularProgress, Link, Typography } from '@material-ui/core';
import { APIS } from '../../../constants/apis';
import { FieldNames, SignUpFormData } from './constants';
import { createSchema } from './schema';
import { useDevPortalApi } from '../../../hooks';
import { STEPS } from '../constants';
import { PersonalDetails } from './components/PersonalDetails';
import { Apis } from './components/Apis';
import { Summary } from './components/Summary';
import { NotificationSnackBar } from '../../NotificationSnackBar';
import { useStyles } from './styles';

interface SignUpFormProps {
  step: number;
  onStepChange: Dispatch<SetStateAction<number>>;
  onDialogClose: () => void;
}

const defaultApisValues = APIS.reduce((acc, { id }) => ({ ...acc, [id]: false }), {});

export const SignUpForm: FC<SignUpFormProps> = memo(({ step, onDialogClose, onStepChange }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm<SignUpFormData>({
    mode: 'all',
    resolver: yupResolver(createSchema(t)),
    defaultValues: {
      [FieldNames.FIRST_NAME]: '',
      [FieldNames.LAST_NAME]: '',
      [FieldNames.BUSINESS_EMAIL]: '',
      [FieldNames.APIS]: defaultApisValues,
    },
  });

  const classes = useStyles();
  const devPortalApi = useDevPortalApi();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const [loading, setLoading] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

  const isFormCompleted = useMemo(() => step === STEPS.length, [step]);
  const isLastStep = useMemo(() => step === STEPS.length - 1, [step]);
  const title = useMemo(() => (isFormCompleted ? 'Thank you for your interest!' : STEPS[step]?.title), [step, isFormCompleted]);

  const increaseStep = useCallback(() => onStepChange((prevActiveStep) => prevActiveStep + 1), [onStepChange]);
  const decreaseStep = useCallback(() => onStepChange((prevActiveStep) => prevActiveStep - 1), [onStepChange]);
  const onFormComplete = useCallback(() => {
    reset();
    onDialogClose();
  }, [reset, onStepChange, onDialogClose]);

  const handleBack = useCallback(() => decreaseStep(), [decreaseStep]);

  const onSubmit = useCallback(
    async (data: SignUpFormData) => {
      const userData = { ...data, apis: Object.keys(data.apis).filter((key) => data.apis[key]) };

      try {
        setLoading(true);

        // Reset reCAPTCHA before generating a new token
        recaptchaRef.current?.reset();
        const recaptchaToken = await recaptchaRef.current?.executeAsync();

        if (!recaptchaToken) {
          throw new Error('Failed reCAPTCHA verification.');
        }

        await devPortalApi.signUp(userData, recaptchaToken);

        increaseStep();
      } catch (error) {
        setNotificationMessage(`An error occurred. ${error instanceof Error ? error?.message : 'Please try again later.'}`);
        setNotificationOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [devPortalApi, increaseStep],
  );

  const handleNext = useCallback(
    (data: SignUpFormData) => {
      if (isLastStep) {
        onSubmit(data);
      } else if (isFormCompleted) {
        onFormComplete();
      } else {
        increaseStep();
      }
    },
    [isLastStep, isFormCompleted, onFormComplete, increaseStep, onSubmit],
  );

  const getStepContent = useCallback(
    (step: number) => {
      switch (step) {
        case 0:
          return <PersonalDetails control={control} errors={errors} />;
        case 1:
          return <Apis control={control} />;
        case 2:
          return <Summary getValues={getValues} ref={recaptchaRef} />;
        case 3:
          return (
            <Typography variant="h4" component="p">
              Our team will follow up with you soon.
            </Typography>
          );
        default:
          return null;
      }
    },
    [control, errors, getValues],
  );

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationMessage('');
  };

  return (
    <form onSubmit={handleSubmit(handleNext)} noValidate>
      <Typography variant="h3" className={classes.stepTitle}>
        {title}
      </Typography>
      {getStepContent(step)}

      <Box my={4} display="flex" justifyContent="center" gridColumnGap={12}>
        {!isFormCompleted && (
          <Button disabled={step === 0 || loading || notificationOpen} onClick={handleBack} variant="outlined">
            Back
          </Button>
        )}
        <Button
          variant="contained"
          type="submit"
          startIcon={loading && <CircularProgress size={24} />}
          disabled={loading || notificationOpen}
        >
          {isLastStep ? 'Submit' : isFormCompleted ? 'Back to home screen' : 'Next'}
        </Button>
      </Box>
      {!isFormCompleted && (
        <>
          <Typography variant="body2" color="textSecondary" align="center">
            This site is protected by reCAPTCHA and the&nbsp;
            <Link href="https://policies.google.com/privacy" target="_blank" underline="always">
              Google Privacy Policy
            </Link>
            &nbsp; and&nbsp;
            <Link href="https://policies.google.com/terms" target="_blank" underline="always">
              Terms of Service
            </Link>
            &nbsp; apply.
          </Typography>
        </>
      )}
      <NotificationSnackBar open={notificationOpen} onClose={handleNotificationClose} message={notificationMessage} type="error" />
    </form>
  );
});
