import React, { FC, useState, memo, PropsWithChildren } from 'react';
import { Box, Button, CircularProgress, Dialog, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export interface DeleteConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  question: string;
  note: string;
  confirmText?: string;
}

export const ConfirmationModal: FC<PropsWithChildren<DeleteConfirmationModalProps>> = memo(
  ({ open, onClose, onConfirm, title, question, note, confirmText = "Confirm", children }) => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const handleConfirmButton = async () => {
      try {
        setLoading(true);
        await onConfirm();
      } finally {
        setLoading(false);
      }
    };

    return (
      <Dialog open={open} aria-labelledby="delete-key-modal" maxWidth="md">
        <Box textAlign="center" py={12} px={12}>
          <Typography variant="overline">{t('eapi-confirmation-text')}</Typography>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          {loading ? (
            <Box my={5}>
              <CircularProgress data-testid="circular-progress" size={50} />
            </Box>
          ) : (
            <>
              <Box mt={4}>
                <Typography variant="h5">{question}</Typography>
              </Box>
              {children}
              <Box mt={2}>
                <Typography variant="body1">{note}</Typography>
              </Box>
              <Box mt={5} display="flex" justifyContent="center" gridColumnGap={16}>
                <Button variant="outlined" onClick={onClose}>
                  {t('eapi-cancel-text')}
                </Button>
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={handleConfirmButton}
                >
                  {confirmText}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    );
  },
);

ConfirmationModal.displayName = 'ConfirmationModal';
