import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Tooltip, Box, Divider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { SDSUser } from '@internal/plugin-eapi-common';
import { useAuth, useSubscriptionApi } from '../../../../../../../../hooks';
import { NotificationSnackBar } from '../../../../../../../../components/NotificationSnackBar';
import { ConfirmationModal } from '../../../../../../../../components/ConfirmationModal';
import { useSubscription } from '../../../../../../../../hooks';
import { ColoredText } from '../../../../../../../../components/ColoredText';

const useStyles = makeStyles(() => ({
  tableCellHead: {
    border: 'none',
  },
  tableCellBody: {
    paddingTop: 0,
    paddingBottom: 0,
    border: 'none',
  },
}));

export const MembersList: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { subscription, setSubscription } = useSubscription();
  const { user } = useAuth();
  const loggedUserEmail = user?.profile?.email;
  const subscriptionsApi = useSubscriptionApi();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removedMemberEmail, setRemovedMemberEmail] = useState<string>('');
  const [notificationType, setNotificationType] = useState<'error' | 'success'>('success');
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);

  if (!subscription) return null;

  const {
    subscriptionDetails: { clientId, users },
  } = subscription;

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setRemovedMemberEmail('');
  }, []);

  const handleModalOpen = useCallback(
    (memberEmail: string) => () => {
      setIsModalOpen(true);
      setRemovedMemberEmail(memberEmail);
    },
    [],
  );

  const handleMemberRemove = useCallback(async () => {
    try {
      await subscriptionsApi.removeMemberFromSubscription(removedMemberEmail, clientId);

      setNotificationOpen(true);
      setNotificationType('success');
      setNotificationMessage(t("eapi-members-delete-success"));

      setSubscription((subscription) =>
        subscription
          ? {
              ...subscription,
              subscriptionDetails: {
                ...subscription.subscriptionDetails,
                users: subscription.subscriptionDetails.users.filter(({ email }) => email !== removedMemberEmail),
              },
            }
          : undefined,
      );
    } catch (e) {
      setNotificationOpen(true);
      setNotificationType('error');
      setNotificationMessage(e instanceof Error ? e.message : t("eapi-unexpected-error"));
    } finally {
      handleModalClose();
    }
  }, [clientId, handleModalClose, removedMemberEmail, subscriptionsApi, user]);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationType('success');
    setNotificationMessage('');
  };

  return (
    <>
      {!users.length ? (
        <Box>
          <Typography align="center">{t("eapi-members-empty-state-text")}</Typography>
        </Box>
      ) : (
        <>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellHead}>{t("eapi-name-text")}</TableCell>
                <TableCell className={classes.tableCellHead}>{t("eapi-email-text")}</TableCell>
                <TableCell className={classes.tableCellHead}>{t("eapi-actions-text")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((member: SDSUser, id) => (
                <TableRow key={id}>
                  <TableCell className={classes.tableCellBody}>{member.name}</TableCell>
                  <TableCell className={classes.tableCellBody}>{member.email}</TableCell>
                  <TableCell className={classes.tableCellBody} width={50} align="center">
                    <Tooltip title={t(member.email === loggedUserEmail ? "eapi-members-disable-delete-info" : "eapi-delete-text")}>
                      <span>
                        <IconButton
                          disabled={member.email === loggedUserEmail}
                          aria-label="close"
                          color="inherit"
                          onClick={handleModalOpen(member.email)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
        <ConfirmationModal
        open={isModalOpen}
        onClose={handleModalClose}
        onConfirm={handleMemberRemove}
        title={t("eapi-member-delete-title-text")}
        question={t("eapi-member-delete-confirm-text")}
        note={t("eapi-member-delete-info-text")}
        confirmText={t("eapi-delete-user-text")}
      >
        <Box mt={4} mb={3}>
          <ColoredText>{removedMemberEmail}</ColoredText>
        </Box>
      </ConfirmationModal>
      <NotificationSnackBar
        open={notificationOpen}
        onClose={handleNotificationClose}
        message={notificationMessage}
        type={notificationType}
      />
    </>
  );
};
