import React, { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, Typography } from '@material-ui/core';
import { CopyTextButton } from '@backstage/core-components';
import { useStyles } from './styles';
export interface GenerateKeyModalProps {
  open: boolean;
  onConfirm: () => void;
  loading: boolean;
  secret: string | null;
}


export const GenerateKeyModal: FC<GenerateKeyModalProps> = memo(({ open, onConfirm, loading, secret }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);

  const CONFIRMATION_LABEL = t("eapi-cred-sym-generate-confirm-text");
  const title = t(secret ? "eapi-cred-sym-generate-success-text" : "eapi-cred-sym-generating-text");

  useEffect(() => {
    setChecked(false);
  }, [secret]);

  return (
    <Dialog open={open} aria-labelledby="generate-secret-modal" maxWidth="md">
      <Box textAlign="center" py={10} px={10}>
        <Typography variant="overline">{t("eapi-credentials-text")}</Typography>
        <Typography variant="h3" gutterBottom>
          {title}
        </Typography>
        {loading ? (
          <Box my={15}>
            <CircularProgress data-testid="circular-progress" size={50} />
          </Box>
        ) : (
          <>
            <Box mt={4}>
              <Typography variant="h5">
                {t("eapi-cred-sym-generate-info")}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="baseline" mt={2} gridColumnGap={8}>
              <Typography variant="h5">{t("eapi-secret-text")}</Typography>
              <Typography variant="body1" className={classes.secret}>
                {secret}
              </Typography>
              <Box>{secret && <CopyTextButton text={secret} />}</Box>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checkbox}
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={CONFIRMATION_LABEL}
            />
          </>
        )}
        <Box mt={4} display="flex" justifyContent="center" gridColumnGap={16}>
          <Button disabled={loading || !checked} variant="contained" onClick={onConfirm}>
          {t("eapi-got-it-text")}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
});

GenerateKeyModal.displayName = 'GenerateKeyModal';
