import { FeatureFlagsConfigType, Translations } from '@internal/plugin-eapi-common';
import { useCoreApi } from '../useCoreApi';
import { configApiRef, discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { UserData } from '../../../components/SignUp/SignUpForm/constants';

export const useDevPortalApi = () => {
  const { doGet, doPost } = useCoreApi();
  const discoveryApi = useApi(discoveryApiRef);
  const configApi = useApi(configApiRef);

  const getSwaggerInfo = async (uuid: string) => {
    try {
      const url = await discoveryApi.getBaseUrl(`swagger-info/uuid/${uuid}`);
      const response = (await doGet(url)) as any;
      return response.data;
    } catch (error: any) {
      console.error('Get Swagger Info call error :: ' + error.message);
      throw error;
    }
  };

  const getFeatureFlags = async () => {
    try {
      const url = await discoveryApi.getBaseUrl('dev-portal/feature-flags');
      const { data } = await doGet<FeatureFlagsConfigType>(url);

      return data;
    } catch (error) {
      console.error('Get Feature Flags call error :: ', error instanceof Error && error.message);
      throw error;
    }
  };

  const signUp = async (data: UserData, token: string | null) => {
    try {
      const baseUrl = configApi.getConfig('swissre.amf').getString('eapiApiBaseUrl');
      const url = `${baseUrl}/user/signup`;
      
      const body = {
          data,
          token
      };
      const response = await doPost(url, body);
       
      return response.data;
    } catch (error) {
        console.error('Sign Up call error :: ', error instanceof Error && error.message);
        throw error;
    }
  };

  const getTranslations = async () => {
    try {
      const url = await discoveryApi.getBaseUrl('dev-portal/translations');
      const { data } = await doGet<{ data: Translations }>(url);
      return data;
    } catch (error) {
      console.error('Get Translations call error :: ', error instanceof Error && error.message);
      throw error;
    }
  };

  return { getSwaggerInfo, getFeatureFlags, signUp, getTranslations };
};
