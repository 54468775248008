import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography, CardProps } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import { DocumentType } from '@internal/plugin-eapi-common';
import { Routes as RoutesPath } from '../../../../../../../../constants/routes';
import { TAB_PATHS as SolutionDetailsTabPaths } from '../../../../../../../SolutionDetails/constants';
import { LinkButton } from '../../../../../../../../components/LinkButton';
import { useStyles } from './styles';

export interface ApiCardProps extends CardProps {
  title: string;
  isDocExist: boolean;
  name?: string;
  documentationType?: string;
}

export const ApiCard: FC<PropsWithChildren<ApiCardProps>> = ({ title, name, isDocExist, documentationType, children }) => {
  const classes = useStyles();
  const navigateUrl = `${RoutesPath.SOLUTIONS}/${name}/${SolutionDetailsTabPaths.ServiceDocumentationTab}${documentationType === DocumentType.TRIAL ? `?docType=${documentationType}` : ''}`;
  const { t } = useTranslation();
   
  return (
    <Card className={classes.card}>
      <CardContent>
        <Box width="100%" display="flex" alignItems="baseline" justifyContent="space-between" pb={2} >
          <Typography variant="h5">{title}</Typography>
          {isDocExist && name && (
            <LinkButton navigateUrl={navigateUrl} target="_blank" variant='secondary'>
              {t("eapi-doc-text")}
              <LaunchIcon fontSize='inherit'/>
            </LinkButton>
          )}
        </Box>
        {children}
      </CardContent>
    </Card>
  );
};
