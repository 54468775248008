import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Subscription } from '@internal/plugin-eapi-common';
import { useNestedPath } from '../../hooks';
import { Page } from '../../layout/Page';
import { SubscriptionPicker } from '../../components/SubscriptionPicker';


export const Subscriptions = () => {
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const navigate = useNavigate();
  const { nestedPath } = useNestedPath();
  const { t } = useTranslation();
  
  const pageTitle = t("eapi-subscriptions-text");
  const pageSubtitle = t("eapi-subscriptions-subtitle-text");

  useEffect(() => {
    if (!selectedSubscription?.clientId) return;

    if (!nestedPath) {
      navigate(`${selectedSubscription.clientId}/apis`);
    };
  }, [selectedSubscription?.clientId, nestedPath, navigate]);

  return (
    <Page title={pageTitle} subtitle={pageSubtitle}>
      <SubscriptionPicker onChange={setSelectedSubscription} />
      {selectedSubscription?.clientId && <Outlet />}
    </Page>
  );
};
