import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, Typography } from '@material-ui/core';
import { UploadKeyForm } from '../UploadKeyForm';

export interface UploadKeyModalProps {
  clientId: string;
  open: boolean;
  onModalClose: () => void;
  onUploadKey: (type: 'success' | 'error', message: string) => void;
}

export const UploadKeyModal: FC<UploadKeyModalProps> = memo(
  ({ clientId, open, onModalClose, onUploadKey }) => {
    const { t } = useTranslation();
    return(
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      aria-labelledby="upload-public-key-modal"
      aria-describedby="upload-public-key-form"
    >
      <Box textAlign="center" py={12} px={12}>
        <Typography variant="overline"> {t('eapi-cred-asym-title-text')}</Typography>
        <Typography variant="h3" gutterBottom>
          {t('eapi-cred-asym-upload-text')}
        </Typography>
        <Typography variant="body1">
        {t('eapi-cred-asym-upload-confirm-text')}
        </Typography>
        <Typography variant="body1" gutterBottom>
        {t('eapi-cred-asym-upload-warning')}
        </Typography>
        <UploadKeyForm
          clientId={clientId}
          onCancel={onModalClose}
          onUploadKey={onUploadKey}
        />
      </Box>
    </Dialog>
    )
    },
);

UploadKeyModal.displayName = 'UploadKeyModal';
