import { TFunction } from 'i18next';
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const NAME_REGEX = /^[a-zA-Z\s'-]+$/;
export const NO_HTML_REGEX = /^[^<>]*$/;

type ValidationType = 'required' | 'min' | 'max' | 'email' | 'name' | 'html';

export enum FormValidationText {
  REQUIRED = 'eapi-form-validation-required-text',
  MIN = 'eapi-form-validation-too-short-text',
  MAX = 'eapi-form-validation-too-long-text',
  EMAIL = 'eapi-form-validation-email-text',
  NAME = 'eapi-form-validation-name-text',
  HTML = 'eapi-form-validation-html-text',
}

export const createErrorMessageGetter = (t: TFunction<'translation', undefined>) => (label: string, type: ValidationType) => {
  switch (type) {
    case 'required':
      return `${t(label)} ${t(FormValidationText.REQUIRED)}`;
    case 'min':
      return `${t(label)} ${t(FormValidationText.MIN)}`;
    case 'max':
      return `${t(label)} ${t(FormValidationText.MAX)}`;
    case 'email':
      return `${t(label)} ${t(FormValidationText.EMAIL)}`;
    case 'name':
      return `${t(label)} ${t(FormValidationText.NAME)}`;
    case 'html':
      return `${t(FormValidationText.HTML)} ${t(label)}`;
    default:
      return '';
  }
};
